import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable, of } from 'rxjs';
import axios from 'app/api/axios';
import { AngularFireAuth } from '@angular/fire/auth';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { Store } from '@ngrx/store';
import { selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { ActingAs, AuthUser, BulkDaAllowedUsers, BulkDaLastRun, FSGlobalConfig, Warehouse } from 'app/interfaces/auth.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { AxiosResponse } from 'axios';
import { ConsignOptions } from './warehouses/manage-consignments/manage-consignments.component';
import { WarehouseCourierConfiguration } from 'app/shared/shared.interfaces';
import { BusinessCourier } from './warehouses/manage-couriers/manage-couriers.interfaces';
import { ProcessBucketOptions } from '../buckets/buckets.interfaces';
import { GetBusinessDetailsResponse, GetLotteryOverflow, GetLotteryRadius, GetPlusDriversWithWarehouseGeoData, PictureProofsIncoming, PictureProofsOut, QrScanningFull, QrScanningOptions } from './settings.interface';
import { LotteryOptions } from './lottery-options/lottery-options.interface';
import { WarehouseDetails } from 'app/shared/assign-driver-modal/assign-driver-modal.interface';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'environments/environment';
import { USE_OLD_ID_TOKEN_INTERCEPTOR } from 'app/shared/interceptors/auth-token-context';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  businessUser$ = new BehaviorSubject(undefined);
  lotterySettingAllowed$ = new BehaviorSubject<boolean>(false);
  set lotterySettingAllowed(allowed: boolean){
    this.lotterySettingAllowed$.next(allowed);
  }

  bankingSettingAllowed$ = new BehaviorSubject<boolean>(false);
  set bankingSettingAllowed(allowed: boolean){
    this.bankingSettingAllowed$.next(allowed);
  }


  bulkDAAllowed$ = new BehaviorSubject<boolean>(false);
  set bulkDAAllowed(allowed: boolean){
    this.bulkDAAllowed$.next(allowed);
  }

  actingAs: ActingAs;
  user: AuthUser;
  constructor(
    private store: Store<any>,
    public afAuth: AngularFireAuth,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private httpClient: HttpClient,
    public firestore: AngularFirestore
  ) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.getAllowedUsers();
    this.getBulkDaAllowedUsers();
  }

  getUserPermissions(user_id, business_id) {
    return axios.get('/user/' + user_id + '/get-access-mapping/' + business_id);
  }

  getUserPermissionsV2(user_id, business_id) {
    return axios({
      method: 'GET',
      url: `/user/${user_id}/get-access-mapping/${business_id}`,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getBusinessUsers(business_id: string): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-users-for-business',
      data: { business_id },
    })
      .then((response) => {
        return response?.data.output;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getWarehouseUsers(warehouse_id: string): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-users-by-warehouse-id',
      data: { warehouse_id },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  sendPasswordResetEmail(user_email) {
    return this.afAuth
      .sendPasswordResetEmail(user_email)
      .then((response) => {
        this.notifyAndUpdate(true, 'Password Reset Email Sent');
        return response;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  notifyAndUpdate(successful, message) {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  inviteUser(emailAddresses) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/invite-persons',
      data: { emails: emailAddresses },
    })
      .then(() => {
        this.notifyAndUpdate(true, 'User Invited');
      })
      .catch(() => {
        this.notifyAndUpdate(false, 'No Email Address Provided');
      });
  }

  removeUser(user_ids) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/remove-users',
      data: user_ids,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'User Successfully deleted');
        this.authService.setActingAs(this.actingAs.id, false);
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getMasterCourierData() {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-couriers',
      data: {},
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  setWarehouseCouriers(couriers: WarehouseCourierConfiguration, warehouse_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: `warehouse/${warehouse_id}/set-couriers`,
      data: couriers,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Warehouse successfully updated');
      })
      .catch(() => {
        this.notifyAndUpdate(false, 'Warehouse failed to update');
      });
  }

  removeWarehouseCouriers(warehouse_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: `warehouse/${warehouse_id}/delete-couriers`,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Warehouse configuration cleared');
      })
      .catch(() => {
        this.notifyAndUpdate(false, 'Warehouse configuration failed to clear');
      });
  }

  setCourierConfiguration(courierBody) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/set-courier-configuration',
      data: courierBody,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Courier Added');
        this.authService.setActingAs(this.actingAs.id, false);
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getBusinessCouriers(): Promise<BusinessCourier[]> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-business-couriers',
      data: { business_id: this.actingAs.id },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  fetchWarehouseData(warehouseId: string): Promise<Warehouse> {
    return axios.get('/warehouse/' + warehouseId + '/details')
      .then((response) => {
        return response.data.warehouse_details
      })
  }

  getWarehouseDrivers(warehouse_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-warehouse-drivers',
      data: {
        warehouse_id: warehouse_id,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getWarehouseCouriers(warehouse_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-warehouse-couriers',
      data: {
        warehouse_id: warehouse_id,
        business_id: this.actingAs.id,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  getConsignmentOptions(warehouse_id: string): Promise<any> {
    return axios({
      method: 'GET',
      url: '/warehouse/' + warehouse_id + '/get-auto-consignment-options',
    }).then((response) => {
      return response?.data;
    });
  }

  getContractorConsignmentRadiusCutOffMeter(warehouse_id: string): Promise<any> {
    return axios({
      method: 'GET',
      url: `/warehouse/${warehouse_id}/get-contractor-consignment-radius-cutoff-meter`,
    }).then((response) => {
      return response?.data;
    });
  }

  setContractorConsignmentRadiusCutOffMeter(warehouse_id: string, radius: number): Promise<any> {
    return axios({
      method: 'POST',
      url: `/warehouse/${warehouse_id}/set-contractor-consignment-radius-cutoff-meter`,
      data: { radius_in_meters: radius },
    }).then((response) => {
      return response?.data;
    });
  }

  getContractorConsignmentRadiusCutOffMeterForBusiness(): Promise<any> {
    return axios({
      method: 'GET',
      url: `/business/${this.actingAs.id}/get-contractor-consignment-radius-cutoff-meter`,
    }).then((response) => {
      return response?.data;
    });
  }

  setContractorConsignmentRadiusCutOffMeterForBusiness(radius: number): Promise<any> {
    return axios({
      method: 'POST',
      url: `/business/${this.actingAs.id}/set-contractor-consignment-radius-cutoff-meter`,
      data: { radius_in_meters: radius },
    }).then((response) => {
      return response?.data;
    });
  }

  setAutoConsignmentOptions(warehouse_id: string, consignment_options: ConsignOptions[]): Promise<void> {
    return axios({
      method: 'POST',
      url: '/warehouse/' + warehouse_id + '/set-auto-consignment-options',
      data: consignment_options,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Options Updated');
        return response.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getBusinessDrivers(business_id: string): Promise<any | AxiosResponse<any>> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-drivers-for-business',
      data: {
        business_id,
      },
    }).then((response) => {
      return response?.data.output;
    });
  }

  deleteCourier(courier_id) {
    return axios({
      method: 'GET',
      url: 'business/' + this.actingAs.id + '/remove-courier-configuration/' + courier_id,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Courier Removed');
        this.authService.setActingAs(this.actingAs.id, false);
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  setAccessMapping(user_id, location_array, user_profile) {
    return axios({
      method: 'POST',
      url: 'user/' + user_id + '/set-access-mapping/' + this.actingAs.id,
      data: { modules: [user_profile], warehouses: location_array },
    })
      .then(() => {
        this.notifyAndUpdate(true, 'User Updated');
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  onAddWarehouse(warehouse_details) {
    return axios({
      method: 'PUT',
      url: 'warehouse/' + this.actingAs.id + '/create',
      data: { warehouse_details },
    })
      .then((res) => {
        this.notifyAndUpdate(true, 'Warehouse Created');
        this.authService.setActingAs(this.actingAs.id, false);
        return res.data
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  onEditWarehouse(warehouse_id: string, warehouse_details: WarehouseDetails): Promise<void> {
    return axios({
      method: 'POST',
      url: 'warehouse/' + warehouse_id + '/update-details',
      data: { warehouse_details },
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Warehouse Updated');
        this.authService.setActingAs(this.actingAs.id, false);
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  deleteWarehouse(warehouse_id) {
    return axios({
      method: 'POST',
      url: '/warehouse/' + warehouse_id + '/set-deleted/',
      data: { is_deleted: true },
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Warehouse Deleted');
        this.authService.setActingAs(this.actingAs.id, false);
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  getPeerWarehouses(warehouse_id) {
    return axios({
      method: 'GET',
      url: '/warehouse/' + warehouse_id + '/peer-warehouses',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  togglePeerWarehouse($event, parent_warehouse) {
    const action = $event.action.event;
    const warehouseId = $event.rows[0].cells.find((cell) => cell.column === 'warehouse_id').value;
    return axios({
      method: 'POST',
      url: 'warehouse/' + parent_warehouse + '/toggle-peer-warehouse/' + action,
      data: [warehouseId],
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Warehouse Toggled');
        this.authService.setActingAs(this.actingAs.id, false);
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  toggleWarehouseDriver($event, parent_warehouse) {
    const driverId = $event.rows[0].cells.find((cell) => cell.column === 'driver_id').value;
    const currentStatus = $event.rows[0].cells.find((cell) => cell.column === 'driver_status').value;
    let context;
    if (currentStatus === 'Unassigned') {
      context = '/add-driver';
    } else {
      context = '/remove-driver';
    }
    return axios({
      method: 'POST',
      url: 'warehouse/' + parent_warehouse + context,
      data: { driver_id: driverId, business_id: this.actingAs.id },
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Driver Toggled');
        this.authService.setActingAs(this.actingAs.id, false);
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getBranding() {
    return axios({
      method: 'GET',
      url: 'enterprise/' + this.actingAs.id + '/get-branding',
    })
      .then((response) => {
        if (response?.data === null) {
          return this.setDefaultBranding();
        }
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  setDefaultBranding() {
    const defaults = {
      logo_url: '',
      primary_color: '#27b7fc',
      secondary_color: '#333333',
      text_color: 'black',
      description: 'Default',
      collection_sms_text: '',
      delivery_sms_text: '',
      parcels_collected_sms_text: '',
    };
    return axios({
      method: 'POST',
      url: 'enterprise/' + this.actingAs.id + '/set-branding',
      data: defaults,
    })
      .then(() => {
        return this.getBranding();
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  setBranding(branding_options, notify = true) {
    return axios({
      method: 'POST',
      url: 'enterprise/' + this.actingAs.id + '/set-branding',
      data: branding_options,
    })
      .then(() => {
        if (notify) {
          this.notifyAndUpdate(true, 'Branding Updated');
        }
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getNotificationsSettings() {
    return axios
      .get('/business/' + this.actingAs.id + '/get-notification-settings')
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  setNotificationsSettings(notifications_settings) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/update-notification-settings ',
      data: { notification_details: notifications_settings },
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Notifications Updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getBusinessDetails(businessId?: string): Promise<GetBusinessDetailsResponse> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-business-details',
      data: { business_id: businessId ? businessId : this.actingAs.id },
    }).then((response) => {
      return response?.data;
    });
  }

  deleteCard(actingAs_id, registrationId) {
    return axios({
      method: 'DELETE',
      url: 'business/' + actingAs_id + '/credit-card/' + registrationId,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Card Deleted');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  setBusinessCostingSettings(costing_settings) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/set-process-bucket-options',
      data: costing_settings,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Options Updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  setWarehouseCostingSettings(warehouseId: string, costing_settings) {
    return axios({
      method: 'POST',
      url: `warehouse/${warehouseId}/set-process-bucket-options`,
      data: costing_settings,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Warehouse Costing Settings saved');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getLotteryOptions(): Promise<LotteryOptions> {
    return axios({
      method: 'GET',
      url: 'business/' + this.actingAs.id + '/get-lottery-options',
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
        throw err;
      });
  }

  setLotteryOptions(lottery_model: LotteryOptions): Promise<void> {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/set-lottery-options',
      data: lottery_model,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Options Updated');
        return;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
        throw err;
      });
  }

  setWarehouseLotteryOptions(warehouseId: string, lottery_model: LotteryOptions): Promise<void> {
    return axios({
      method: 'POST',
      url: `warehouse/${warehouseId}/set-lottery-options`,
      data: lottery_model,
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Options Updated');
        return;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
        throw err;
      });
  }

  getBusinessCostingSettings() {
    return axios({
      method: 'GET',
      url: 'business/' + this.actingAs.id + '/get-process-bucket-options',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getWarehouseCostingSettings(warehouseId: string): Promise<ProcessBucketOptions | void> {
    return axios({
      method: 'GET',
      url: `warehouse/${warehouseId}/get-process-bucket-options`,
    })
      .then((response: AxiosResponse<ProcessBucketOptions>) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getPaymentOptions() {
    return axios({
      method: 'GET',
      url: 'business/' + this.actingAs.id + '/get-payment-options',
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  updatePaymentOptions(payment_model) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/update-payment-options',
      data: payment_model,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Options Updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getPicupPlusDriversWithWarehouseGeocode(business_id: string, warehouse_id: string): Promise<GetPlusDriversWithWarehouseGeoData> {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-picupplus-drivers-for-warehouse-with-warehouse-geocode',
      data: { warehouse_id, business_id },
    })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getPicupPlusDrivers(business_id: string, warehouse_id: string): Promise<any[]> {
    let url;
    let APIdata;
    if (warehouse_id) {
      url = '/generic/cqrs/get-picupplus-drivers-for-warehouse';
      APIdata = { warehouse_id: warehouse_id, business_id: business_id };
    } else {
      url = '/generic/cqrs/get-picupplus-drivers-for-business';
      APIdata = { business_id: business_id };
    }
    return axios({
      method: 'POST',
      url: url,
      data: APIdata,
    })
      .then((response) => {
        return response?.data.output;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  addPicupPlusDriver(
    business_id: string,
    warehouse_id: string | null,
    driver_id: string,
    can_see_earnings: boolean
  ): Promise<any> {
    let url;
    let APIdata;
    if (warehouse_id) {
      url = 'warehouse/' + warehouse_id + '/add-picupplus-driver';
      APIdata = {
        driver_id,
        business_id,
        can_see_earnings: can_see_earnings,
      };
    } else {
      url = 'business/' + business_id + '/add-picupplus-driver';
      APIdata = {
        driver_id,
        can_see_earnings: can_see_earnings,
      };
    }
    return axios({
      method: 'POST',
      url: url,
      data: APIdata,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  removePicupPlusDriver(business_id: string, warehouse_id: string | null, driver_id: string): Promise<any> {
    let url;
    let APIdata;
    if (warehouse_id) {
      url = 'warehouse/' + warehouse_id + '/remove-picupplus-driver';
      APIdata = {
        driver_id,
        business_id,
      };
    } else {
      url = 'business/' + business_id + '/remove-picupplus-driver';
      APIdata = {
        driver_id,
      };
    }
    return axios({
      method: 'POST',
      url: url,
      data: APIdata,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getBusinessLotteryRadius(): Promise<GetLotteryRadius> {
    return axios({
      method: 'GET',
      url: `business/${this.actingAs.id}/get-custom-lottery-radius`,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  updateBusinessLotteryRadius(amount: number): Promise<any> {
    return axios({
      method: 'POST',
      url: `business/${this.actingAs.id}/set-custom-lottery-radius/${amount}`,
      data: amount,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Lottery radius updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  clearBusinessLotteryRadius(): Promise<any> {
    return axios({
      method: 'DELETE',
      url: `business/${this.actingAs.id}/clear-custom-lottery-radius`,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Radius cleared');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getWarehouseLotteryRadius(warehouse_id: string): Promise<GetLotteryRadius> {
    return axios({
      method: 'GET',
      url: `warehouse/${warehouse_id}/get-custom-lottery-radius`,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  async getWarehouseLotteryOptions(warehouse_id: string): Promise<LotteryOptions> {
    try {
      const response = await axios({
        method: 'GET',
        url: `warehouse/${warehouse_id}/get-lottery-options`,
      });
      return response?.data;
    } catch (err) {
      this.notifyAndUpdate(false, err.response?.data.message);
    }
  }

  updateWarehouseLotteryRadius(warehouse_id: string, amount: number): Promise<any> {
    return axios({
      method: 'POST',
      url: `warehouse/${warehouse_id}/set-custom-lottery-radius/${amount}`,
      data: amount,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Lottery radius updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  clearWarehouseLotteryRadius(warehouse_id: string): Promise<any> {
    return axios({
      method: 'DELETE',
      url: `warehouse/${warehouse_id}/clear-custom-lottery-radius`,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Radius cleared');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getLotteryOverflow(): Promise<GetLotteryOverflow> {
    return axios({
      method: 'GET',
      url: `business/${this.actingAs.id}/get-contractor-lottery-overflows-to-picup`,
    })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  updateLotteryOverflow(allowOverflow: boolean): Promise<any> {
    return axios({
      method: 'POST',
      url: `business/${this.actingAs.id}/set-contractor-lottery-overflows-to-picup/${allowOverflow}`,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Lottery overflow updated');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  clearLotteryOverflow(): Promise<any> {
    return axios({
      method: 'DELETE',
      url: `business/${this.actingAs.id}/clear-contractor-lottery-overflows-to-picup`,
    })
      .then((response) => {
        this.notifyAndUpdate(true, 'Overflow cleared');
        return response?.data;
      })
      .catch((err) => {
        this.notifyAndUpdate(false, err.response?.data.message);
      });
  }

  getProofOptionsForBusiness(businessId: string): Observable<any>{
    return this.httpClient.get(`${environment.integration.rootUrl}business/${businessId}/get-confirmation-photo-rules`,{
      headers: {Authorization: `Bearer ${localStorage.getItem('id_token')}`}
    })
  }

  pushProofOptionsForBusiness(body: PictureProofsOut, businessId: string): Observable<any> {
    return this.httpClient.post(`${environment.integration.rootUrl}business/${businessId}/update-confirmation-photo-rules`,
      body,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('id_token')}`}
      });
  }

  overrideScanningOptions(body: QrScanningOptions, warehouseId: string): Observable<any> {
    return this.httpClient.post(`${environment.integration.rootUrl}warehouse/${warehouseId}/override-waybill-scanning-options`,
      body,
      {
        context: new HttpContext().set(USE_OLD_ID_TOKEN_INTERCEPTOR, true)
      });
  }

  getBusinessWaybillOptions(businessId: string): Observable<QrScanningFull[]> {
    return this.httpClient.get<QrScanningFull[]>(`${environment.integration.rootUrl}warehouse/get-warehouses-override-waybill-states/${businessId}`,
      {
        context: new HttpContext().set(USE_OLD_ID_TOKEN_INTERCEPTOR, true)
      }).pipe(
      switchMap((QrSettings: QrScanningFull[]) => {
        return of(QrSettings);
      }),
    );
  }

  saveWarehouseOperatingTimes(warehouseId: string, warehouse_details: WarehouseDetails ): Observable<string> {
    return this.httpClient.post<string>(`${environment.integration.rootUrl}warehouse/${warehouseId}/update-details`,
      {
        warehouse_details: warehouse_details
      },
      {
        context: new HttpContext().set(USE_OLD_ID_TOKEN_INTERCEPTOR, true)
      })
  }

  getAllowedUsers(): void {
    const collection = this.firestore.collection('frontend-config');
    collection
      .doc('global')
      .valueChanges()
      .pipe(take(1))
      .subscribe((data: FSGlobalConfig) => {
        if(data.users_allow_lottery_changes.length > 0) {
          this.lotterySettingAllowed = data.users_allow_lottery_changes.some(email => email === this.user?.email);
        } else {
          this.lotterySettingAllowed = false;
        }

        if(data.users_allow_banking_changes.length > 0) {
          this.bankingSettingAllowed = data.users_allow_banking_changes.some(email => email?.toLowerCase() === this.user?.email?.toLowerCase());
        } else {
          this.bankingSettingAllowed = false;
        }
      });
  }

  getBulkDaAllowedUsers(): void {
    const collection = this.firestore.collection('bulk-direct-assignment');
    collection
      .doc('allowed-users')
      .valueChanges()
      .pipe(take(1))
      .subscribe((data: BulkDaAllowedUsers) => {
        if(data.users.length > 0) {
          this.bulkDAAllowed = data.users.some(email => email === this.user?.email);
        } else {
          this.bulkDAAllowed = false;
        }
      });
  }

  getOrdersForDA(businessId: string): Observable<OrderId[]> {
    return this.httpClient.post<OrderId[]>(`${environment.integration.rootUrl}generic/cqrs/get-orders-for-direct-assignment`,
      {business_id: businessId},
      {
        context: new HttpContext().set(USE_OLD_ID_TOKEN_INTERCEPTOR, true)
      });
  }

  runDaForOrder(orderIds: string[]): Observable<any>{
    return this.httpClient.post(`${environment.integration.rootUrl}order/start-lotteries-with-retries`,
      {
        order_ids: orderIds,
        start_lottery_at: null,
        fleet_allocation: 'Picup'
      },
      {
        context: new HttpContext().set(USE_OLD_ID_TOKEN_INTERCEPTOR, true)
      });
  }

  SaveUserAndTimeStampInFirestoreForBulkDA(): void {
    const collection = this.firestore.collection('bulk-direct-assignment');
    collection
      .doc(this.actingAs.id)
      .set({
        businessName: this.actingAs.name,
        timestamp: new Date(),
        userName: this.user.name,
        userId: this.user.user_id
      })
  }

  getUserAndTimeStampInFirestoreForBulkDA(): Observable<BulkDaLastRun> {
    const collection = this.firestore.collection<BulkDaLastRun>('bulk-direct-assignment');
    return collection
      .doc(this.actingAs.id)
      .valueChanges();
  }

  SetBulkDAInactive(): void {
    const collection = this.firestore.collection('bulk-direct-assignment');
    collection
      .doc(this.actingAs.id)
      .update({
        isActive: false
      })
  }
}

export interface OrderId {
  OrderId: string
}

